import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import { LoadingIndicator } from '../primitive/LoadingIndicator';
import { getIntlLocale, getIntlCurrencyCode } from '../intl/intlSelectors';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Plugins from '../plugins/Plugins';
import PromoBanner from '../promoDisplay/PromoBanner';
import NotificationPortal from '../notifications/NotificationPortal';
import ScrollToTopWrap from '../primitive/ScrollToTopWrap';
import { getDisplayBannerPaths } from '../routing/flowSelectors';
import { flowFactory } from '../routing/FlowRoute';
import { INTERRUPT_ROUTE_3DS_STEP_UP, INTERRUPT_ROUTE_3DS_CALLBACK } from '../routing/InterruptRoutes';
import ProgramIntlProvider from '../program/ProgramIntlProvider';
import { ConnectedOldGDPRConsentBanner } from '../consent/OldGDPRConsentBanner';
import { ConnectedTestEnvironmentBanner } from '../testEnvironment/TestEnvironmentBanner';
import { getBrandName } from '../brand/brandSelectors';
import headerMessages from '../header/headerMessages';

import { getEnv } from './bootstrap';
import appStore from './appStore';
import { getShowAppLoadingSpinner } from './appSelectors';
import { PayerAuthenticationStep } from '../payerAuthentication/PayerAuthenticationStep';
import StepUpPopOut from '../payerAuthentication/StepUpPopOut';

const Flow = flowFactory(appStore.getState());
const render3dsFlow = () => {
    const isStepUpWindow = window.location.href.includes(INTERRUPT_ROUTE_3DS_STEP_UP);
    if (isStepUpWindow) {
        return <StepUpPopOut />;
    }
    return null;
};

const render3dscallback = (locale, currency, intl) => {
    const is3dscallback = window.location.href.includes(INTERRUPT_ROUTE_3DS_CALLBACK);
    if (is3dscallback) {
        return (
            <div className="app" key={`${locale}-${currency}`}>
                <PayerAuthenticationStep intl={intl} />
            </div>
        );
    }
    return null;
};



export const App = (props) => {
    const {
        intl,
        loading,
        locale,
        currency,
        displayBannerPaths,
        brandName
    } = props;
    const showTestEnv = getEnv() !== 'PROD';

    const tag = document.querySelector('meta[name="description"]');
    if (tag) {
        tag.content = intl.formatMessage(headerMessages.metaDescriptionContents, { brandName });
    }

    const payerAuthenticationCallbackInterrupt = render3dscallback(locale, currency, intl);

    if (payerAuthenticationCallbackInterrupt) {
        return payerAuthenticationCallbackInterrupt;
    }

    const payerAuthenticationInterrupt = render3dsFlow();

    if (payerAuthenticationInterrupt) {
        return payerAuthenticationInterrupt;
    }

    return (
        <div className="app" key={`${locale}-${currency}`}>
            <Helmet
                titleTemplate={`%s | ${intl.formatMessage(headerMessages.pageTitle, { brandName })}`}
                defaultTitle={intl.formatMessage(headerMessages.pageTitle, { brandName })}
            >
                {}
                <html lang={locale} />
            </Helmet>
            {}
            {showTestEnv && (
                <ConnectedTestEnvironmentBanner />
            )}

            <Header {...props} />
            <ConnectedOldGDPRConsentBanner />
            {}

            <Route path={displayBannerPaths} component={PromoBanner} />

            {}
            <main className="app-wrapper">
                {loading && <LoadingIndicator overlay />}
                <Flow {...props} />
            </main>

            {}
            <Plugins {...props} />

            {}
            <Footer {...props} />

            <NotificationPortal {...props} />
        </div>
    );
};

App.defaultProps = {
    loading: false,
    currency: null
};

App.propTypes = {
    loading: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    currency: PropTypes.string,
    displayBannerPaths: PropTypes.array.isRequired,
    brandName: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    loading: getShowAppLoadingSpinner(state),
    locale: getIntlLocale(state), 
    currency: getIntlCurrencyCode(state), 
    displayBannerPaths: getDisplayBannerPaths(state),
    brandName: getBrandName(state)
});

export const ConnectedApp = connect(mapStateToProps)(injectIntl(App));


const ReduxApp = ({ history }) => (
    <Provider store={appStore}>
        <ProgramIntlProvider>
            <Router history={history}>
                {}
                <ScrollToTopWrap>
                    <Route component={ConnectedApp} />
                </ScrollToTopWrap>
            </Router>
        </ProgramIntlProvider>
    </Provider>
);

ReduxApp.propTypes = {
    history: PropTypes.object.isRequired
};

export default ReduxApp;
